export const parseGoogleSheetResponse = (responseText: string): unknown => {
	// if (responseText.startsWith(prefix) && responseText.endsWith(suffix)) {
	// 	const jsonString = responseText.slice(prefix.length, -suffix.length);
	// 	return JSON.parse(jsonString);
	// }

	// throw new Error("Invalid Google Sheets response format");

	const prefix = "google.visualization.Query.setResponse(";
	const suffix = ");";

	const prefixIndex = responseText.indexOf(prefix);
	const suffixIndex = responseText.lastIndexOf(suffix);

	if (prefixIndex === -1 || suffixIndex === -1) {
		console.dir(responseText);
		throw new Error("Invalid Google Sheets response format");
	}

	const jsonString = responseText.slice(
		prefixIndex + prefix.length,
		suffixIndex
	);
	return JSON.parse(jsonString);
};
