/**
 * Returns the next item in a collection relative to the current item.
 * If the current item is the last in the collection, it wraps around to the first item.
 *
 * @template T - The type of elements in the collection.
 * @param {Iterable<T> | ArrayLike<T> | Record<string, T>} items - The collection of items.
 * @param {T} current - The current item in the collection.
 * @returns {T} - The next item in the collection.
 */

export function nextItem<T>(
	items: Iterable<T> | ArrayLike<T> | Record<string, T>,
	current: T
): T {
	const itemArray = Array.isArray(items)
		? items
		: items instanceof Map || items instanceof Set
		? Array.from(items)
		: Object.values(items);
	const currentIndex = itemArray.indexOf(current);
	const nextIndex = (currentIndex + 1) % itemArray.length;
	return itemArray[nextIndex];
}
