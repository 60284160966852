"use client";
import Image from "next/image";
import { ReactNode } from "react";
import styles from "./AuthBoundary.module.css";
import { useAuth } from "./AuthContext";
import RandomFaviconBackground from "./RandomFaviconBackground";

export const AuthBoundary = ({ children }: { children: ReactNode }) => {
	const { user, signIn } = useAuth();
	if (!user) {
		return (
			<div className={styles.signInContainer}>
				<RandomFaviconBackground />
				<div className={styles.content}>
					<Image
						src={"./img/northern_reach_text_white.svg"}
						alt="Northern Reach Logo"
						width={200}
						height={100}
						style={{ padding: "1rem" }}
					/>
					<button
						className={styles.signInButton}
						onClick={async () => {
							console.debug("signing in");
							await signIn();
						}}
					>
						Sign In with Google
					</button>
				</div>
			</div>
		);
	}

	return children;
};
