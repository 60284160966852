import React, { createContext, useContext, useState } from "react";

type ConfigContextProps = {
	sheetId?: string;
	gid?: string;
	setSheetId: (sheetId: string) => void;
	setGid: (gid: string) => void;
};
export const ConfigContext = createContext<ConfigContextProps>({
	setSheetId: () => {},
	setGid: () => {},
});
export const ConfigProvider: React.FC<{
	children?: React.ReactNode;
	sheetId: string;
	gid: string;
}> = (props) => {
	const [sheetId, setSheetId] = useState<string>(props.sheetId);
	const [gid, setGid] = useState<string>(props.gid);

	return (
		<ConfigContext.Provider value={{ sheetId, gid, setSheetId, setGid }}>
			{props.children}
		</ConfigContext.Provider>
	);
};
export const useConfig = (): ConfigContextProps => {
	if (ConfigContext === null) {
		throw new Error("useConfig must be used within a ConfigProvider");
	}
	return useContext(ConfigContext);
};
