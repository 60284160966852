"use client";

import { AuthContextProvider } from "@/components/AuthContext";
import { ConfigProvider } from "@/components/ConfigContext";
import { ControlProvider } from "@/components/ControlContext";
import { DataProvider } from "@/components/DataContext";
import { MapComponentProps } from "@/components/MapComponent/MapComponent";
import { MapLayerProvider } from "@/components/MapLayerContext";
import "@/components/sentryClient";
import { UserPreferenceProvider } from "@/components/UserPreferenceContext";
import { InitialData } from "@/types/InitialData";
import dynamic from "next/dynamic";
import { JSX } from "react";
import { AuthBoundary } from "./AuthBoundary";
import { ErrorBoundary } from "./ErrorBoundary";
import { FilterProvider } from "./FilterContext";
import { TopBanner } from "./TopBanner";

const MapComponent = dynamic<MapComponentProps>(
	() =>
		import("@/components/MapComponent/MapComponent").then(
			(mod) => mod.MapComponent
		),
	{
		loading: () => <p>Loading...</p>,
		ssr: false,
	}
);

export const App = (props: {
	sheetId: string;
	gid: string;
	initialData: InitialData;
}) => {
	return (
		<AuthContextProvider>
			<AuthBoundary>
				<ConfigProvider sheetId={props.sheetId} gid={props.gid}>
					<UserPreferenceProvider>
						<MapLayerProvider>
							<ControlProvider>
								<DataProvider initialData={props.initialData}>
									<FilterProvider>
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												height: "100vh",
											}}
										>
											<TopBanner />
											<div style={{ flex: 1 }}>
												<MapComponent />
											</div>
										</div>
									</FilterProvider>
								</DataProvider>
							</ControlProvider>
						</MapLayerProvider>
					</UserPreferenceProvider>
				</ConfigProvider>
			</AuthBoundary>
		</AuthContextProvider>
	);
};

const AppWithErrorBoundary = (
	props: JSX.IntrinsicAttributes & {
		sheetId: string;
		gid: string;
		initialData: InitialData;
	}
) => (
	<ErrorBoundary>
		<App {...props} />
	</ErrorBoundary>
);
export default AppWithErrorBoundary;
