"use client";
import { useEffect, useState } from "react";
import { nextItem } from "./MapComponent/nextItem";
import styles from "./RandomFaviconBackground.module.css";

function getConcurrency() {
	if (typeof window === "undefined") return 0;
	const nav: Navigator = window.navigator;
	const availableLogicalCores = nav.hardwareConcurrency;
	console.debug("Available logical cores:", availableLogicalCores);
	return availableLogicalCores;
}

type Range = {
	min: number;
	max: number;
};

type PresetData = {
	name: string;
	movementDirections: number[];
	relativeIconOrientations: number[];
	iconCount: number;
	depthRange: Range;
	sizeRange: Range;
	speedRange: Range;
};

const Preset = {
	up: "up",
	down: "down",
	left: "left",
	right: "right",
	octilinear: "octilinear",
	random: "random",
	static: "static",
} as const;
type Preset = (typeof Preset)[keyof typeof Preset];

const defaults = {
	iconCount: 15,
	depthRange: { min: 5, max: 10 },
	sizeRange: { min: 15, max: 35 },
	speedRange: { min: 15, max: 30 },
	relativeIconOrientations: [0],
} satisfies Partial<PresetData>;

const config: Map<Preset, PresetData> = new Map();

config.set(Preset.up, {
	...defaults,
	name: "Up",
	movementDirections: [0],
});

config.set(Preset.down, {
	...defaults,
	name: "Down",
	movementDirections: [180],
	relativeIconOrientations: [180],
});

config.set(Preset.left, {
	...defaults,
	name: "Left",
	movementDirections: [270],
});

config.set(Preset.right, {
	...defaults,
	name: "Right",
	movementDirections: [90],
});

config.set(Preset.octilinear, {
	...defaults,
	name: "Octilinear",
	movementDirections: Array.from({ length: 8 }, (_, i) => i * 45),
});

config.set(Preset.random, {
	...defaults,
	name: "Random",
	movementDirections: Array.from(
		{ length: defaults.iconCount },
		(_, i) => i
	).map((_, i) => Math.random() * 360),
});

config.set(Preset.static, {
	...defaults,
	name: "Static",
	movementDirections: Array.from(
		{ length: defaults.iconCount },
		() => Math.random() * 360
	),
	speedRange: { min: 0, max: 0 },
});

export default function RandomFaviconBackground() {
	const [directionMode, setDirectionMode] = useState<Preset>(Preset.down);
	const [concurrency, setConcurrency] = useState<number>(0);

	useEffect(() => {
		setConcurrency(getConcurrency());
	}, []);

	const basePreset = config.get(directionMode)!;
	const preset: PresetData = getOptimizedPreset(basePreset, concurrency);

	const favicons = Array.from({ length: preset?.iconCount }, (_, i) => {
		const depth =
			Math.random() * (preset.depthRange.max - preset.depthRange.min) +
			preset.depthRange.min;

		const size =
			preset.sizeRange.max -
			((depth - preset.depthRange.min) /
				(preset.depthRange.max - preset.depthRange.min)) *
				(preset.sizeRange.max - preset.sizeRange.min);

		const speed =
			((depth - preset.depthRange.min) /
				(preset.depthRange.max - preset.depthRange.min)) *
				(preset.speedRange.max - preset.speedRange.min) +
			preset.speedRange.min;
		const delay = Math.random() * 5;
		const left = Math.random() * 100;
		const startPosition = Math.random() * 100;
		const opacity = Math.random() * 0.5 + 0.5;
		const movementAngle =
			preset.movementDirections[i % preset.movementDirections.length];
		const iconAngle =
			preset.relativeIconOrientations[
				i % preset.relativeIconOrientations.length
			];

		return (
			<img
				key={i}
				src="/img/northern_reach_icon.svg"
				alt="favicon"
				className={`${styles.favicon} ${
					concurrency === 0 || directionMode === Preset.static
						? styles.static
						: ""
				}`}
				style={
					{
						left:
							directionMode === Preset.static
								? `${left}%`
								: directionMode === Preset.left
								? `100vw`
								: directionMode === Preset.right
								? `-10vw`
								: `${left}%`,
						top:
							directionMode === Preset.static
								? `${startPosition}vh`
								: directionMode === Preset.up
								? `100vh`
								: directionMode === Preset.down
								? `-10vh`
								: directionMode === Preset.octilinear ||
								  directionMode === Preset.random
								? `${Math.random() > 0.5 ? 100 : -10}vh`
								: `${startPosition}vh`,
						width: `${size}px`,
						height: `${size}px`,
						animationDuration:
							concurrency === 0 || directionMode === Preset.static
								? undefined
								: `${speed}s`,
						animationDelay:
							concurrency === 0 || directionMode === Preset.static
								? undefined
								: `${delay}s`,
						opacity,
						"--depth": depth,
						"--movement-angle": `${movementAngle}deg`,
						"--icon-angle": `${iconAngle}deg`,
					} as React.CSSProperties
				}
			/>
		);
	});

	return (
		<div className={styles.background}>
			<button
				className={styles.directionToggle}
				onClick={() => {
					const next = nextItem(Preset, directionMode);
					setDirectionMode(next);
				}}
			>
				{preset.name}
			</button>
			{favicons}
		</div>
	);
}
function getOptimizedPreset(
	basePreset: PresetData,
	concurrency: number
): PresetData {
	if (concurrency === 0) {
		return {
			...basePreset,
			relativeIconOrientations: [0],
			speedRange: { min: 0, max: 0 },
		};
	} else {
		const concurrencyRatio = concurrency / basePreset.iconCount;
		console.debug("Concurrency ratio:", concurrencyRatio);
		const weight = Math.min(1, concurrencyRatio);

		const speedRange = {
			min: basePreset.speedRange.min * weight,
			max: basePreset.speedRange.max * weight,
		};
		return {
			...basePreset,
			speedRange,
		};
	}
}
