"use client";
import { useState } from "react";
import { AuthBoundary } from "./AuthBoundary";
import { AuthContextProvider, useAuth } from "./AuthContext";
import styles from "./CurrentUser.module.css";

export function CurrentUser() {
	const { user, signOut, switchUser } = useAuth();
	const [confirmLogout, setConfirmLogout] = useState(false);
	const [animationClass, setAnimationClass] = useState("");

	const handleLogout = () => {
		if (confirmLogout) {
			signOut();
		} else {
			setConfirmLogout(true);
			setAnimationClass("active");
			setTimeout(() => {
				setConfirmLogout(false);
				setAnimationClass("");
			}, 3000); // Reset after 3 seconds
		}
	};

	if (!user) {
		throw new Error("User not signed in");
	}
	return (
		<AuthContextProvider>
			<AuthBoundary>
				<div className={styles.container}>
					<button className={styles.button} onClick={switchUser}>
						Switch User
						<div className={styles.userInfoHover}>
							<span>{user.displayName}</span>
							<span>({user.email})</span>
						</div>
					</button>
					<button
						className={`${styles.button} ${
							confirmLogout ? `${styles.confirming}` : ""
						}`}
						onClick={handleLogout}
					>
						<span>{confirmLogout ? "Confirm" : "Logout"}</span>
					</button>
				</div>
			</AuthBoundary>
		</AuthContextProvider>
	);
}
