import { MarkerData } from "@/types/MarkerData";
import { isValid, parse, parseISO } from "date-fns";
import { enGB, enUS } from "date-fns/locale";

const dateFormats = [
	// ISO format first for better performance on well-formed dates
	{ format: "yyyy-MM-dd", locale: enGB }, // ISO format
	{ format: "dd-MM-yyyy", locale: enGB }, // UK format
	{ format: "dd/MM/yyyy", locale: enGB }, // UK format with slashes
	{ format: "MM/dd/yyyy", locale: enUS }, // US format
	{ format: "dd-MM-yy", locale: enGB }, // Short year UK
	{ format: "MM/dd/yy", locale: enUS }, // Short year US
];

export function parseDate(dateOfEvent: string): Date {
	// First try ISO parsing for dates that are already in ISO format
	const isoDate = parseISO(dateOfEvent);
	if (isValid(isoDate)) {
		return isoDate;
	}

	// Then try our format patterns
	for (const { format, locale } of dateFormats) {
		const parsedDate = parse(dateOfEvent, format, new Date(), { locale });
		if (isValid(parsedDate)) {
			return parsedDate;
		}
	}

	// Last resort fallback - assume UK format (dd-MM-yyyy)
	const cleanDate = dateOfEvent.replace(/[^0-9]/g, "-");
	const [day, month, year] = cleanDate.split("-").map((n) => parseInt(n, 10));

	const date = new Date(year > 999 ? year : 2000 + year, month - 1, day);

	if (isValid(date)) {
		return date;
	}

	throw new Error(`Could not parse date: ${dateOfEvent}`);
}

const parsedDateCache = new Map<string, Date>();

export function parseMarkerDate(marker: MarkerData): Date {
	if (marker.parsedDate) {
		return marker.parsedDate;
	}

	if (parsedDateCache.has(marker.dateOfEvent)) {
		return parsedDateCache.get(marker.dateOfEvent)!;
	}

	const parsedDate = parseDate(marker.dateOfEvent);
	parsedDateCache.set(marker.dateOfEvent, parsedDate);
	return parsedDate;
}
