"use client";
import { bannerBackgroundColor } from "@/constants";
import Image from "next/image";
import { MdBrightnessAuto, MdDarkMode, MdLightMode } from "react-icons/md";
import { CurrentUser } from "./CurrentUser";
import { ThemeMode, useTheme } from "./ThemeContext";
import { nextItem } from "./MapComponent/nextItem";

export const TopBanner = () => {
	const { theme, setTheme } = useTheme();
	const cycleTheme = () => setTheme(nextItem(ThemeMode, theme));

	const getThemeIcon = () => {
		if (theme === "auto") return <MdBrightnessAuto />;
		if (theme === "dark") return <MdDarkMode />;
		if (theme === "light") return <MdLightMode />;
	};

	return (
		<div
			id="banner"
			style={{
				backgroundColor: bannerBackgroundColor,
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				padding: "0.5rem 2rem",
			}}
		>
			<Image
				src={"./img/northern_reach_text_white.svg"}
				alt="Northern Reach Logo"
				objectFit="contain"
				width={200}
				height={100}
				style={{
					padding: "1rem",
				}}
			/>
			<div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
				<button className="theme-toggle" onClick={cycleTheme}>
					{getThemeIcon()}
				</button>
				<CurrentUser />
			</div>
		</div>
	);
};
