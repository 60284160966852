import { Engagement } from "@/types/Engagement";
import { MarkerData } from "@/types/MarkerData";
import { Row } from "@/types/Row";
import { Coordinate, geocodePostcode } from "@/utils/geocode";
import { fetchGoogleSheetsData } from "@/utils/googleSheets";

export async function* getMarkers(
	sheetId: string,
	gid: string
): AsyncGenerator<MarkerData> {
	const rows: Row[] = await fetchGoogleSheetsData(sheetId, gid);

	const postcodeLocations: Map<
		string,
		{
			postcode: string;
			geocodeData?: { latitude: number; longitude: number } | null;
		}
	> = new Map();

	for (const row of rows) {
		const businessName = row.c[0]?.v as string;
		const industry = row.c[1]?.v as string;
		const contactEmail = row.c[2]?.v as string;
		const postcode = row.c[4]?.v as string;
		const eventType = row.c[5]?.v as string;
		const dateOfEvent = row.c[6]?.f as string;

		if (!postcode) {
			continue;
		}

		if (!postcodeLocations.has(postcode)) {
			postcodeLocations.set(postcode, {
				postcode,
				geocodeData: await geocodePostcode(postcode),
			});
		}
		const geocodeData = postcodeLocations.get(postcode)?.geocodeData;
		if (geocodeData) {
			yield {
				businessName,
				industry,
				contactEmail,
				postcode,
				eventType,
				dateOfEvent,
				lat: geocodeData.latitude,
				lng: geocodeData.longitude,
			};
		}
	}
}

export async function getMarkerData(
	sheetId: string,
	gid: string
): Promise<MarkerData[]> {
	const engagements: Engagement[] = await retrieveEngagementData(sheetId, gid);

	const uniquePostCodes = new Set(
		engagements
			.map((engagement: Engagement) => engagement.postcode)
			.filter(Boolean)
	);

	const postcodeLocations: Map<
		string,
		{
			postcode: string;
			geocodeData?: { latitude: number; longitude: number } | null;
		}
	> = new Map();
	await Promise.all(
		Array.from(uniquePostCodes).map(async (postcode: string) => {
			postcodeLocations.set(postcode, {
				postcode,
				geocodeData: await geocodePostcode(postcode),
			});
		})
	);

	const markerData = await Promise.all(
		engagements.map(async (engagement: Engagement) => {
			const postcode = engagement.postcode;
			if (!postcode) {
				return null;
			}

			const geocodeData = postcodeLocations.get(postcode)?.geocodeData;

			if (geocodeData) {
				return {
					...engagement,
					lat: geocodeData.latitude,
					lng: geocodeData.longitude,
				};
			}
			return null;
		})
	);

	return markerData.filter(Boolean) as MarkerData[];
}

export async function* fetchGeocodedEngagements(
	sheetId: string,
	gid: string
): AsyncGenerator<MarkerData> {
	const engagements: Engagement[] = await retrieveEngagementData(sheetId, gid);

	const postcodeLocations: Map<
		string,
		{
			postcode: string;
			geocodeData?: { latitude: number; longitude: number } | null;
		}
	> = new Map();

	for (const engagement of engagements) {
		const postcode = engagement.postcode;
		if (!postcode) {
			continue;
		}

		if (!postcodeLocations.has(postcode)) {
			postcodeLocations.set(postcode, {
				postcode,
				geocodeData: await geocodePostcode(postcode),
			});
		}
		const geocodeData = postcodeLocations.get(postcode)?.geocodeData;
		if (geocodeData) {
			yield {
				...engagement,
				lat: geocodeData.latitude,
				lng: geocodeData.longitude,
			};
		}
	}
}

export async function retrieveEngagementData(
	sheetId: string,
	gid: string
): Promise<Engagement[]> {
	const rows: Row[] = await fetchGoogleSheetsData(sheetId, gid);
	const engagements: Engagement[] = rows.map(parseRowData);
	return engagements;
}

export function parseRowData(row: Row): Engagement {
	const businessName = row.c[0]?.v as string;
	const industry = row.c[1]?.v as string;
	const contactEmail = row.c[2]?.v as string;
	const postcode = row.c[4]?.v as string;
	const eventType = row.c[5]?.v as string;
	const dateOfEvent = row.c[6]?.f as string;
	return {
		postcode,
		businessName,
		industry,
		contactEmail,
		eventType,
		dateOfEvent,
	};
}

export async function updateMarkerData(
	sheetId: string,
	gid: string,
	postcodeMap: Map<string, Coordinate>,
	markers: MarkerData[]
): Promise<{ markers: MarkerData[]; postcodes: Map<string, Coordinate> }> {
	const engagements: Engagement[] = await retrieveEngagementData(sheetId, gid);

	const uniquePostCodes = new Set(
		engagements
			.map((engagement: Engagement) => engagement.postcode.toUpperCase())
			.filter(Boolean)
	);

	const missingPostCodes = Array.from(uniquePostCodes).filter(
		(postcode) => !postcodeMap.has(postcode)
	);

	console.info(
		`Found ${uniquePostCodes.size} unique postcodes, ${missingPostCodes.length} missing geocodes`
	);

	for await (const postcode of missingPostCodes) {
		const usedBy = engagements.filter(
			(engagement) =>
				engagement.postcode.toUpperCase() === postcode.toUpperCase()
		);
		console.info(
			`Fetching geocode for postcode "${postcode}" used by ${usedBy.length} engagements`
		);
		const geocodeData = await geocodePostcode(postcode);
		if (geocodeData) {
			console.info(
				`Geocoded postcode "${postcode}" to ${geocodeData.latitude}, ${geocodeData.longitude}`
			);
			postcodeMap.set(postcode, geocodeData);
		} else {
			console.warn(`Failed to geocode postcode "${postcode}"`);
		}
	}

	for (const engagement of engagements) {
		const postcode = engagement.postcode;
		if (!postcode) {
			continue;
		}

		const geocodeData = postcodeMap.get(postcode);
		if (geocodeData) {
			const existingMarker = markers.find((marker): boolean => {
				// all properties except lat and lng
				const { lat, lng, ...rest } = marker;
				marker.postcode = marker.postcode.toUpperCase();
				return JSON.stringify(rest) === JSON.stringify(engagement);
			});
			if (existingMarker) {
				Object.assign(existingMarker, {
					...engagement,
					lat: geocodeData.latitude,
					lng: geocodeData.longitude,
				});
			} else {
				markers.push({
					...engagement,
					lat: geocodeData.latitude,
					lng: geocodeData.longitude,
				});
			}
		}
	}

	return { markers, postcodes: postcodeMap };
}
