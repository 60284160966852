import React, { createContext, useContext, useEffect, useState } from "react";
import { useMapLayers } from "./MapLayerContext";
import { useUserPreferences } from "./UserPreferenceContext";

const ControlContext = createContext<{
	heatmap: {
		visible: boolean;
		setVisible(visible: boolean): void;
		toggleVisible(): void;
	};
	clustering: {
		enabled: boolean;
		setEnabled(enabled: boolean): void;
		toggleEnabled(): void;
	};
	markers: {
		visible: boolean;
		setVisible(visible: boolean): void;
		toggleVisible(): void;
	};
	viewport: {
		enabled: boolean;
		setEnabled(enabled: boolean): void;
		toggleEnabled(): void;
	};
	zoomToFit: {
		trigger(): void;
		value: number;
	};
}>({
	heatmap: {
		visible: true,
		setVisible: () => {},
		toggleVisible: () => {},
	},
	clustering: {
		enabled: true,
		setEnabled: () => {},
		toggleEnabled: () => {},
	},
	markers: {
		visible: true,
		setVisible: () => {},
		toggleVisible: () => {},
	},
	viewport: {
		enabled: true,
		setEnabled: () => {},
		toggleEnabled: () => {},
	},
	zoomToFit: {
		trigger: () => {},
		value: 0,
	},
});

export function ControlProvider({ children }: { children: React.ReactNode }) {
	const { getPreference, setPreference, removePreference } =
		useUserPreferences();
	const { dataLayers } = useMapLayers();

	const [heatmapVisible, setHeatmapVisible] = useState(() =>
		getPreference<boolean>("heatmapVisible", true)
	);
	const [clusteringEnabled, setClusteringEnabled] = useState(() =>
		getPreference<boolean>("clusteringEnabled", true)
	);
	const [pointsVisible, setPointsVisible] = useState(() =>
		getPreference<boolean>("pointsVisible", true)
	);
	const [viewportEnabled, setViewportEnabled] = useState(() =>
		getPreference<boolean>("viewportEnabled", true)
	);
	const [zoomToFitTriggerValue, setZoomToFitTrigger] = useState(0);

	useEffect(() => {
		setHeatmapVisible(
			dataLayers.find((layer) => layer.id === "heatmap")?.enabled ?? true
		);
		setPointsVisible(
			dataLayers.find((layer) => layer.id === "markers")?.enabled ?? true
		);
	}, [dataLayers]);

	return (
		<ControlContext.Provider
			value={{
				heatmap: {
					visible: heatmapVisible,
					setVisible: (visible: boolean) => {
						setHeatmapVisible(visible);
						setPreference("heatmapVisible", visible);
					},
					toggleVisible: () => {
						setHeatmapVisible((prev) => {
							setPreference("heatmapVisible", !prev);
							return !prev;
						});
					},
				},
				clustering: {
					enabled: clusteringEnabled,
					setEnabled: (enabled: boolean) => {
						setClusteringEnabled(enabled);
						setPreference("clusteringEnabled", enabled);
					},
					toggleEnabled: () => {
						setClusteringEnabled((prev) => {
							setPreference("clusteringEnabled", !prev);
							return !prev;
						});
					},
				},
				markers: {
					visible: pointsVisible,
					setVisible: (visible: boolean) => {
						setPointsVisible(visible);
						setPreference("pointsVisible", visible);
					},
					toggleVisible: () => {
						setPointsVisible((prev) => {
							setPreference("pointsVisible", !prev);
							return !prev;
						});
					},
				},
				viewport: {
					enabled: viewportEnabled,
					setEnabled: (enabled: boolean) => {
						setViewportEnabled(enabled);
						setPreference("viewportEnabled", enabled);
					},
					toggleEnabled: () => {
						setViewportEnabled((prev) => {
							setPreference("viewportEnabled", !prev);
							return !prev;
						});
					},
				},
				zoomToFit: {
					trigger: () => {
						setZoomToFitTrigger((prev) => prev + 1);
					},
					value: zoomToFitTriggerValue,
				},
			}}
		>
			{children}
		</ControlContext.Provider>
	);
}

export function useControls() {
	const context = useContext(ControlContext);
	if (!context) {
		throw new Error("useControls must be used within a ControlProvider");
	}
	return context;
}
