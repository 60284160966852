import { useControls } from "@/components/ControlContext";
import { MapLayer } from "@/types/MapLayer";
import React, { createContext, useContext, useEffect, useState } from "react";

interface MapLayerContextType {
	tileLayers: MapLayer[];
	dataLayers: MapLayer[];
	setTileLayers: React.Dispatch<React.SetStateAction<MapLayer[]>>;
	setDataLayers: React.Dispatch<React.SetStateAction<MapLayer[]>>;
	toggleLayer: (id: string) => void;
	updateOpacity: (id: string, opacity: number) => void;
	reorderTileLayers: (id: string, newOrder: number) => void;
	reorderDataLayers: (id: string, newOrder: number) => void;
	moveTileLayerUp: (id: string) => void;
	moveTileLayerDown: (id: string) => void;
	moveDataLayerUp: (id: string) => void;
	moveDataLayerDown: (id: string) => void;
}

const MapLayerContext = createContext<MapLayerContextType | undefined>(
	undefined
);

export const MapLayerProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const { heatmap, markers } = useControls();

	const [tileLayers, setTileLayers] = useState<MapLayer[]>([
		{
			id: "osm",
			name: "OpenStreetMap",
			type: "tile",
			enabled: true,
			opacity: 1,
			order: 0,
			url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
			attribution: "&copy; OpenStreetMap",
			theme: "both",
		},
		{
			id: "carto",
			name: "CartoDB",
			type: "tile",
			enabled: true,
			opacity: 0.95,
			order: 1,
			attribution: "&copy; CartoDB",
			variants: {
				light: {
					url: "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
					theme: "light",
				},
				dark: {
					url: "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png",
					theme: "dark",
				},
			},
		},
	]);

	const [dataLayers, setDataLayers] = useState<MapLayer[]>([
		{
			id: "markers",
			name: "Markers",
			type: "marker",
			enabled: true,
			order: 2,
		},
		{
			id: "heatmap",
			name: "Heatmap",
			type: "heatmap",
			enabled: true,
			order: 3,
		},
	]);

	const toggleLayer = (id: string) => {
		setTileLayers(
			tileLayers.map((layer) =>
				layer.id === id ? { ...layer, enabled: !layer.enabled } : layer
			)
		);
		setDataLayers(
			dataLayers.map((layer) =>
				layer.id === id ? { ...layer, enabled: !layer.enabled } : layer
			)
		);
	};

	const updateOpacity = (id: string, opacity: number) => {
		setTileLayers(
			tileLayers.map((layer) =>
				layer.id === id ? { ...layer, opacity } : layer
			)
		);
		setDataLayers(
			dataLayers.map((layer) =>
				layer.id === id ? { ...layer, opacity } : layer
			)
		);
	};

	const reorderTileLayers = (id: string, newOrder: number) => {
		setTileLayers((prevLayers) => {
			const layer = prevLayers.find((l) => l.id === id);
			if (!layer) return prevLayers;

			const updatedLayers = prevLayers
				.filter((l) => l.id !== id)
				.map((l) => {
					if (l.order >= newOrder && l.order < layer.order) {
						return { ...l, order: l.order + 1 };
					} else if (l.order <= newOrder && l.order > layer.order) {
						return { ...l, order: l.order - 1 };
					}
					return l;
				});

			return [...updatedLayers, { ...layer, order: newOrder }].sort(
				(a, b) => b.order - a.order // Ensure descending order
			);
		});
	};

	const reorderDataLayers = (id: string, newOrder: number) => {
		setDataLayers((prevLayers) => {
			const layer = prevLayers.find((l) => l.id === id);
			if (!layer) return prevLayers;

			const updatedLayers = prevLayers
				.filter((l) => l.id !== id)
				.map((l) => {
					if (l.order >= newOrder && l.order < layer.order) {
						return { ...l, order: l.order + 1 };
					} else if (l.order <= newOrder && l.order > layer.order) {
						return { ...l, order: l.order - 1 };
					}
					return l;
				});

			return [...updatedLayers, { ...layer, order: newOrder }].sort(
				(a, b) => b.order - a.order // Ensure descending order
			);
		});
	};

	const moveTileLayerUp = (id: string) => {
		const layer = tileLayers.find((l) => l.id === id);
		if (layer && layer.order < tileLayers.length - 1) {
			// Ensure it doesn't exceed max order
			reorderTileLayers(id, layer.order + 1); // Increase order to move up
		}
	};

	const moveTileLayerDown = (id: string) => {
		const layer = tileLayers.find((l) => l.id === id);
		if (layer && layer.order > 0) {
			// Ensure it doesn't go below 0
			reorderTileLayers(id, layer.order - 1); // Decrease order to move down
		}
	};

	const moveDataLayerUp = (id: string) => {
		const layer = dataLayers.find((l) => l.id === id);
		if (layer && layer.order < dataLayers.length - 1) {
			// Ensure it doesn't exceed max order
			reorderDataLayers(id, layer.order + 1); // Increase order to move up
		}
	};

	const moveDataLayerDown = (id: string) => {
		const layer = dataLayers.find((l) => l.id === id);
		if (layer && layer.order > 0) {
			// Ensure it doesn't go below 0
			reorderDataLayers(id, layer.order - 1); // Decrease order to move down
		}
	};

	useEffect(() => {
		setDataLayers((prevLayers) =>
			prevLayers.map((layer) => {
				if (layer.id === "heatmap") {
					return { ...layer, enabled: heatmap.visible };
				}
				if (layer.id === "markers") {
					return { ...layer, enabled: markers.visible };
				}
				return layer;
			})
		);
	}, [heatmap.visible, markers.visible]);

	useEffect(() => {
		heatmap.setVisible(
			dataLayers.find((layer) => layer.id === "heatmap")?.enabled ?? true
		);
		markers.setVisible(
			dataLayers.find((layer) => layer.id === "markers")?.enabled ?? true
		);
	}, [dataLayers]);

	return (
		<MapLayerContext.Provider
			value={{
				tileLayers,
				dataLayers,
				setTileLayers,
				setDataLayers,
				toggleLayer,
				updateOpacity,
				reorderTileLayers,
				reorderDataLayers,
				moveTileLayerUp,
				moveTileLayerDown,
				moveDataLayerUp,
				moveDataLayerDown,
			}}
		>
			{children}
		</MapLayerContext.Provider>
	);
};

export const useMapLayers = () => {
	const context = useContext(MapLayerContext);
	if (context === undefined) {
		throw new Error("useMapLayers must be used within a MapLayerProvider");
	}
	return context;
};
