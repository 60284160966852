import * as Sentry from "@sentry/react";
import { BrowserOptions } from "@sentry/react";
import { Client, ErrorEvent, FeedbackInternalOptions } from "@sentry/types";

const sentryClient: Client | undefined = Sentry.init({
	environment: process.env.NODE_ENV,
	attachStacktrace: true,
	dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
	integrations: [],

	// Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
	tracesSampleRate: 1,

	tracePropagationTargets: [
		"localhost",
		/https:\/\/.*\.exadev\.io/,
		/https:\/\/.*\.onrender\.com/,
		/https:\/\/.*\.github\.io/,
	],

	// Define how likely Replay events are sampled.
	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 1.0,

	// Define how likely Replay events are sampled when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	// Setting this option to true will print useful information to the console while you're setting up Sentry.
	debug: process.env.NODE_ENV === "development",
	sendDefaultPii: true,

	autoSessionTracking: true,
	enabled: process.env.NODE_ENV === "production" || !!process.env.ENABLE_SENTRY,
	sendClientReports: true,

	beforeSend(event: ErrorEvent) {
		if (event.exception && event.event_id) {
			console.debug("An exception occurred", event.exception);
			// showErrorReportDialog(event);
		} else {
			console.debug("No exception or event ID found");
		}
		return event;
	},

	release: new Date().toISOString(),
} satisfies BrowserOptions);

import("@sentry/browser").then((lazyLoadedSentry) => {
	Sentry.addIntegration(
		lazyLoadedSentry.replayIntegration({
			minReplayDuration: 1000,
			flushMinDelay: 1000,
			flushMaxDelay: 10000,

			stickySession: true,
			maskAllText: false,
			blockAllMedia: false,
			maskAllInputs: false,

			onError(err: unknown) {
				console.error(err);
			},
		})
	);
	Sentry.addIntegration(
		lazyLoadedSentry.browserTracingIntegration({
			instrumentNavigation: true,
			instrumentPageLoad: false,
			enableLongAnimationFrame: false,
			enableLongTask: false,
		})
	);
	Sentry.addIntegration(lazyLoadedSentry.captureConsoleIntegration());
	Sentry.addIntegration(lazyLoadedSentry.contextLinesIntegration());
	Sentry.addIntegration(lazyLoadedSentry.debugIntegration());
	Sentry.addIntegration(lazyLoadedSentry.extraErrorDataIntegration());
	Sentry.addIntegration(
		lazyLoadedSentry.feedbackIntegration({
			colorScheme: "system",
			formTitle: "Send feedback",
			isEmailRequired: false,
			isNameRequired: false,
			triggerLabel: "Feedback",
			showBranding: false,
			messagePlaceholder: "",
			submitButtonLabel: "Submit",
			enableScreenshot: true,
		} satisfies Partial<FeedbackInternalOptions>)
	);
	Sentry.addIntegration(lazyLoadedSentry.httpClientIntegration());
	Sentry.addIntegration(lazyLoadedSentry.moduleMetadataIntegration());
	Sentry.addIntegration(
		lazyLoadedSentry.replayCanvasIntegration({
			enableManualSnapshot: true,
		})
	);
	Sentry.addIntegration(lazyLoadedSentry.reportingObserverIntegration());
	Sentry.addIntegration(lazyLoadedSentry.rewriteFramesIntegration());
	Sentry.addIntegration(lazyLoadedSentry.sessionTimingIntegration());
	Sentry.addIntegration(lazyLoadedSentry.spotlightBrowserIntegration());
});

export default sentryClient;
