"use client";

import { auth } from "@/firebaseConfig";
import * as Sentry from "@sentry/react";
import {
	AuthProvider,
	signOut as firebaseSignOut,
	GoogleAuthProvider,
	onAuthStateChanged,
	signInWithPopup,
	User,
} from "firebase/auth";

import { createContext, useContext, useEffect, useState } from "react";

export type AuthContextType = {
	user: User | null;
	signIn: () => Promise<void>;
	signOut: () => Promise<void>;
	switchUser: () => Promise<void>;
};

const AuthContext = createContext<AuthContextType>({
	user: null,
	signIn: async () => {},
	signOut: async () => {},
	switchUser: async () => {},
});

import { ReactNode } from "react";

interface AuthProviderProps {
	children: ReactNode;
}

export const AuthContextProvider = ({ children }: AuthProviderProps) => {
	const [user, setUser] = useState<User | null>(null);

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			setUser(user);
			Sentry.setUser(
				user && {
					id: user.uid,
					...(user.displayName && { username: user.displayName }),
					...(user.email && { email: user.email }),
				}
			);
		});
		return () => unsubscribe();
	}, []);

	const signIn = async () => {
		console.log("Signing in");
		const provider: AuthProvider = new GoogleAuthProvider();
		try {
			await signInWithPopup(auth, provider);
		} catch (error) {
			console.error("Error signing in:", error);
		}
	};

	const signOut = async () => {
		console.debug("Signing out");
		try {
			await firebaseSignOut(auth);
		} catch (error) {
			console.error("Error signing out:", error);
		}
	};

	const switchUser = async () => {
		console.debug("Switching user");
		await signIn();
	};

	return (
		<AuthContext.Provider value={{ user, signIn, signOut, switchUser }}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	return useContext(AuthContext);
};
