import { GoogleSheetsResponse } from "@/types/GoogleSheetsResponse";
import { Row } from "@/types/Row";
import { parseGoogleSheetResponse } from "./parseGoogleSheetResponse";

const cache: { [key: string]: Row[] } = {};

export const fetchGoogleSheetsData = async (
	sheetId: string,
	gid: string
): Promise<Row[]> => {
	const cacheKey = `${sheetId}_${gid}`;
	if (cache[cacheKey]) {
		return cache[cacheKey];
	}

	const dataSourceUrl = `https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?tqx=out:json&tq&gid=${gid}`;

	const response = await fetch(dataSourceUrl);
	const responseText = await response.text();
	const jsonData: GoogleSheetsResponse = parseGoogleSheetResponse(
		responseText
	) as any;

	const rows = jsonData.table.rows as unknown as Row[];
	cache[cacheKey] = rows;
	return rows;
};
