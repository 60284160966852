import { MarkerData } from "@/types/MarkerData";
import { LatLngBoundsExpression } from "leaflet";

export type Coordinate = {
	latitude: number;
	longitude: number;
};

export const postcodeOutcodeRegex = /^[A-Z]{1,2}\d[A-Z\d]?$/;
export const postcodeFullRegex = /^[A-Z]{1,2}\d[A-Z\d]?\s\d[A-Z]{2}$/;
const postcodeishRegex = /^([A-Z]{1,2}\d{1,2}[A-Z]?)\d[A-Z]{2}$/;

export function isOutwardCode(postcode: string): boolean {
	return postcodeOutcodeRegex.test(postcode);
}
export function isFullPostcode(postcode: string): boolean {
	return postcodeFullRegex.test(postcode);
}
export function formatPostcode(postcode: string): string {
	// Remove any non-alphanumeric characters and extra spaces
	const cleaned = postcode.replace(/\s+/g, "").toUpperCase();
	// Regular expression to match valid UK postcodes
	const match = cleaned.match(postcodeishRegex);
	if (match) {
		// Insert space before the incode
		return `${match[1]} ${cleaned.slice(match[1].length)}`;
	}
	return cleaned;
}

export async function geocodePostcode(
	postcode: string
): Promise<Coordinate | null> {
	// Format and validate the postcode
	postcode = formatPostcode(postcode);

	if (postcode.match(postcodeFullRegex)) {
		try {
			const result = await postcodeLookup(postcode);
			if (isCoordinateValid(result)) {
				return result;
			}
		} catch (error) {
			console.warn(`Error while calling postcodeLookup(${postcode})`);
		}
	}
	if (postcode.match(postcodeOutcodeRegex)) {
		try {
			const result = await outwardCodeLookup(postcode);

			if (isCoordinateValid(result)) {
				return result;
			}
		} catch (error) {
			console.warn(`Error while calling outwardCodeLookup(${postcode})`);
		}
	}
	try {
		const result = await autocompletePostcode(postcode);

		if (isCoordinateValid(result)) {
			return result;
		}
	} catch (error) {
		console.warn(`Error while calling autocompletePostcode(${postcode})`);
	}

	try {
		console.log("Falling back to looking up outward code");
		const outwardCode = postcode.split(" ")[0];
		const result = await outwardCodeLookup(outwardCode);

		if (isCoordinateValid(result)) {
			return result;
		}
	} catch (error) {
		console.warn(`Error while calling outwardCodeLookup(${postcode})`);
	}

	console.error(`Failed to geocode postcode "${postcode}"`);
	return null;
}

export interface PostCodeLookupResponse {
	status: number;
	result: PostcodeResult;
}

export interface PostcodeResult {
	postcode: string;
	quality: number;
	eastings: number;
	northings: number;
	country: string;
	nhs_ha: string;
	longitude: number;
	latitude: number;
	european_electoral_region: string;
	primary_care_trust: string;
	region: any;
	lsoa: string;
	msoa: string;
	incode: string;
	outcode: string;
	parliamentary_constituency: string;
	parliamentary_constituency_2024: string;
	admin_district: string;
	parish: string;
	admin_county: any;
	date_of_introduction: string;
	admin_ward: string;
	ced: any;
	ccg: string;
	nuts: string;
	pfa: string;
	codes: Codes;
}

export interface Codes {
	admin_district: string;
	admin_county: string;
	admin_ward: string;
	parish: string;
	parliamentary_constituency: string;
	parliamentary_constituency_2024: string;
	ccg: string;
	ccg_id: string;
	ced: string;
	nuts: string;
	lsoa: string;
	msoa: string;
	lau2: string;
	pfa: string;
}

const postcodesIoBaseUrl = "https://api.postcodes.io";
function isCoordinateValid(result: Coordinate) {
	return result && result.latitude && result.longitude;
}

export async function postcodeLookup(postcode: string): Promise<Coordinate> {
	const postcodeApiUrl = `${postcodesIoBaseUrl}/postcodes/${encodeURIComponent(
		postcode
	)}`;
	console.debug(`Fetching "${postcode}" from "${postcodeApiUrl}"`);
	const postcodeData: PostCodeLookupResponse = await (
		await fetch(postcodeApiUrl)
	).json();
	return {
		latitude: postcodeData.result.latitude,
		longitude: postcodeData.result.longitude,
	};
}

export interface ONSPostCodeLookupResponse {
	postcode: string;
	latitude: number;
	longitude: number;
}

export type OutwardCodeLookup = {
	status: number;
	result: Outcode;
};

export type Outcode = {
	outcode: string;
	longitude: number;
	latitude: number;
	northings: number;
	eastings: number;
	admin_district: string[];
	parish: string[];
	admin_county: any[];
	admin_ward: string[];
	country: string[];
	parliamentary_constituency: string[];
};

export async function outwardCodeLookup(
	outwardCode: string
): Promise<Coordinate> {
	const postcodeApiUrl = `${postcodesIoBaseUrl}/outcodes/${encodeURIComponent(
		outwardCode
	)}`;
	console.debug(`Fetching "${outwardCode}" from "${postcodeApiUrl}"`);
	const postcodeData: OutwardCodeLookup = await (
		await fetch(postcodeApiUrl)
	).json();
	return {
		latitude: postcodeData.result.latitude,
		longitude: postcodeData.result.longitude,
	};
}

export type PostcodeAutocomplete = {
	status: number;
	result: string[];
};

export async function autocompletePostcode(
	postcode: string
): Promise<Coordinate> {
	const autocompleteApiUrl = `${postcodesIoBaseUrl}/postcodes/${encodeURIComponent(
		postcode
	)}/autocomplete`;
	console.debug(`Fetching "${postcode}" from "${autocompleteApiUrl}"`);
	const data: PostcodeAutocomplete = await (
		await fetch(autocompleteApiUrl)
	).json();

	return await postcodeLookup(data.result[0]);
}

export function calculateCentre(
	markers: MarkerData[],
	useMeanLocation = false
): Coordinate {
	if (!useMeanLocation) {
		return calculateBoundingBoxCentre(markers);
	} else {
		return computeMeanLocation(markers);
	}
}

export function computeMeanLocation(markers: MarkerData[]) {
	const totalMarkers = markers.length;
	const totalLat = markers.reduce((acc, marker) => acc + marker.lat, 0);
	const totalLng = markers.reduce((acc, marker) => acc + marker.lng, 0);

	const centre = {
		latitude: totalLat / totalMarkers,
		longitude: totalLng / totalMarkers,
	};

	console.log("Centre:", centre);
	return centre;
}

export function calculateBoundingCoordinates(markers: MarkerData[]): {
	latitude: { min: number; max: number };
	longitude: { min: number; max: number };
} {
	const latitudes = markers.map((marker) => marker.lat);
	const longitudes = markers.map((marker) => marker.lng);

	return {
		latitude: {
			min: Math.min(...latitudes),
			max: Math.max(...latitudes),
		},
		longitude: {
			min: Math.min(...longitudes),
			max: Math.max(...longitudes),
		},
	};
}

export function calculateBoundingBoxCentre(markers: MarkerData[]): {
	latitude: number;
	longitude: number;
} {
	const bounding = calculateBoundingCoordinates(markers);

	return {
		latitude: (bounding.latitude.min + bounding.latitude.max) / 2,
		longitude: (bounding.longitude.min + bounding.longitude.max) / 2,
	};
}

export function calculateBounds(markers: MarkerData[]): LatLngBoundsExpression {
	const bounding = calculateBoundingCoordinates(markers);

	return [
		[bounding.latitude.min, bounding.longitude.min],
		[bounding.latitude.max, bounding.longitude.max],
	];
}
