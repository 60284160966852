import { MarkerData } from "@/types/MarkerData";
import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useData } from "./DataContext";

import { FilterKey } from "@/components/MapComponent/panels/left/filters/types/filterKey";
import { Filters } from "@/components/MapComponent/panels/left/filters/types/filters";
import { FilterSetters } from "@/components/MapComponent/panels/left/filters/types/filterSetters";
import { parseMarkerDate } from "./MapComponent/parseDate";

export type Predicate<T> = (value: T) => boolean;
export type FilterFunction = Predicate<MarkerData>;

type FilterContextType = {
	view: MarkerData[];
	filters: Filters;
} & FilterSetters & {
		applyFilters: () => void;
		selectedMarker: MarkerData | null;
		setSelectedMarker: (marker: MarkerData | null) => void;
		startDate: Date;
		endDate: Date;
		setStartDate: (date: Date) => void;
		setEndDate: (date: Date) => void;
	};

const FilterContext = createContext<FilterContextType | null>(null);

const createInitialFilters = () =>
	Object.fromEntries(
		["date", "industry", "interaction", "viewport"].map((key) => [
			`${key}Filter`,
			() => true,
		])
	) as unknown as Filters;

export function FilterProvider({ children }: { children: React.ReactNode }) {
	const { markers } = useData();
	const [view, setView] = useState<MarkerData[]>(markers);
	const [filters, setFilters] = useState<Filters>(createInitialFilters());
	const [selectedMarker, setSelectedMarker] = useState<MarkerData | null>(null);

	// Initialize dates only once using useMemo
	const initialDates = useMemo(() => computeDateRange(markers), [markers]);
	const [startDate, setStartDate] = useState<Date>(initialDates.startDate);
	const [endDate, setEndDate] = useState<Date>(initialDates.endDate);

	function createFilterSetter(
		key: FilterKey
	): (filter: FilterFunction) => void {
		return (filter: FilterFunction) => {
			setFilters((prevFilters) => ({
				...prevFilters,
				[`${key}Filter`]: filter,
			}));
		};
	}

	// Memoize filter setters
	const filterSetters = useMemo(() => {
		return Object.fromEntries(
			["date", "industry", "interaction", "viewport"].map((key) => [
				`set${key.charAt(0).toUpperCase() + key.slice(1)}Filter`,
				createFilterSetter(key as FilterKey),
			])
		) as FilterSetters;
	}, []);

	// Memoize applyFilters function
	const applyFilters = useCallback(() => {
		const filteredMarkers = markers.filter((marker) =>
			Object.values(filters).every((filter) => filter(marker))
		);
		setView(filteredMarkers);
	}, [filters, markers]);

	// Apply filters when dependencies change
	useEffect(() => {
		applyFilters();
	}, [applyFilters]);

	const contextValue = useMemo(
		() => ({
			view,
			filters,
			...filterSetters,
			selectedMarker,
			setSelectedMarker,
			startDate,
			endDate,
			setStartDate,
			setEndDate,
			applyFilters,
		}),
		[
			view,
			filters,
			filterSetters,
			selectedMarker,
			startDate,
			endDate,
			applyFilters,
		]
	);

	return (
		<FilterContext.Provider value={contextValue}>
			{children}
		</FilterContext.Provider>
	);
}

// Helper function to compute the date range from markers
function computeDateRange(markers: MarkerData[]): {
	startDate: Date;
	endDate: Date;
} {
	if (markers.length === 0) {
		const today = new Date();
		const monthAgo = new Date();
		monthAgo.setMonth(monthAgo.getMonth() - 1);
		return {
			startDate: monthAgo,
			endDate: today,
		};
	}

	const dates = markers
		.map((marker) => parseMarkerDate(marker))
		.filter((date) => !isNaN(date.getTime()));

	const startDate = new Date(Math.min(...dates.map((date) => date.getTime())));
	const endDate = new Date(Math.max(...dates.map((date) => date.getTime())));

	return {
		startDate,
		endDate,
	};
}

export function useFilters() {
	const context = useContext(FilterContext);
	if (!context) {
		throw new Error("useFilters must be used within a FilterProvider");
	}
	return context;
}
