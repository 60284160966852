import { InitialData } from "@/types/InitialData";
import { MarkerData } from "@/types/MarkerData";
import { PostcodeMap } from "@/types/PostcodeMap";
import { Coordinate } from "@/utils/geocode";
import { updateMarkerData } from "@/utils/getMarkerData";
import React, { Context, createContext, useContext, useState } from "react";

type AppInitialData = {
	initialData: InitialData;
	//
	markers: MarkerData[];
	addMarker: (marker: MarkerData) => void;
	addMarkers: (markers: MarkerData[]) => void;
	//
	postcodes: PostcodeMap;
	addPostcode: (postcode: string, coordinate: Coordinate) => void;
	addPostcodes: (
		postcodes: { postcode: string; coordinate: Coordinate }[]
	) => void;
	//
	fetchData: () => Promise<void>;
};

export const DataContext: Context<AppInitialData> =
	createContext<AppInitialData>({
		initialData: { markers: [], postcodes: new Map() },
		//
		markers: [],
		addMarker: () => {},
		addMarkers: () => {},
		//
		postcodes: new Map<string, Coordinate>(),
		addPostcode: () => {},
		addPostcodes: () => {},
		//
		fetchData: async () => {},
	});

export const DataProvider: React.FC<{
	initialData: InitialData;
	children?: React.ReactNode;
}> = (props) => {
	const [markers, setMarkers] = useState(props.initialData.markers);
	const [postcodes, setPostcodes] = useState(props.initialData.postcodes);
	const [, setLoading] = useState<boolean>(false);
	const [, setCache] = useState<InitialData | null>(null);

	const addPostcode = (postcode: string, coordinate: Coordinate) => {
		if (postcodes.has(postcode)) {
			throw new Error(`Postcode ${postcode} already exists`);
		} else {
			const newPostcodes = new Map(postcodes);
			newPostcodes.set(postcode, coordinate);
			setPostcodes(newPostcodes);
		}
	};

	const addMarker = (marker: MarkerData) => setMarkers([...markers, marker]);

	const addMarkers = (newMarkers: MarkerData[]) => setMarkers(newMarkers);

	const addPostcodes = (
		newPostcodes: {
			postcode: string;
			coordinate: Coordinate;
		}[]
	) =>
		newPostcodes.forEach(({ postcode, coordinate }) =>
			addPostcode(postcode, coordinate)
		);

	const fetchData = async () => {
		setLoading(true);
		try {
			const { markers: newMarkers, postcodes: newPostcodes } =
				await updateMarkerData(
					process.env.SHEET_ID!,
					process.env.GID!,
					new Map(postcodes),
					[]
				);

			setPostcodes(newPostcodes);
			setMarkers(newMarkers);
			setCache({ markers: newMarkers, postcodes: newPostcodes });
		} catch (error) {
			console.error("Error fetching data:", error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<DataContext.Provider
			value={{
				addMarker,
				addMarkers,
				addPostcode,
				addPostcodes,
				markers: markers,
				postcodes: postcodes,
				initialData: props.initialData,
				fetchData,
			}}
		>
			{props.children}
		</DataContext.Provider>
	);
};

export const useData = (): AppInitialData => {
	if (DataContext === null) {
		throw new Error("useData must be used within a DataProvider");
	}
	return useContext(DataContext);
};
